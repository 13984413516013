::ng-deep {
  table {
    * {
      border: 0 solid #d9d9e3;
      box-sizing: border-box;
    }
    word-wrap: break-word;
    white-space: pre-wrap;

    font-size: 0.875em;
    margin-bottom: 2em;
    margin-top: 2em;
    table-layout: auto;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-color: inherit;
    text-indent: 0;
    border-spacing: 0 0;

    thead {
      border-bottom-color: #4b5563;
      border-bottom-width: 1px;

      th {
        background-color: rgba(161, 161, 161, 0.2);
        border-bottom-width: 1px;
        border-left-width: 1px;
        border-top-width: 1px;
        padding: 0.25rem 0.75rem;
        font-weight: 600;

        &:first-child {
          border-top-left-radius: 0.375rem;
        }

        &:last-child {
          border-right-width: 1px;
          border-top-right-radius: 0.375rem;
        }
      }
    }

    td {
      &:last-child {
        border-right-width: 1px;
      }
    }

    tbody {
      tr {
        border-bottom-color: #374151;
        border-bottom-width: 1px;

        th {
          background-color: rgba(161, 161, 161, 0.2);
          border-bottom-width: 1px;
          border-left-width: 1px;
          border-top-width: 1px;
          padding: 0.25rem 0.75rem;
          font-weight: 600;

          &:first-child {
            border-top-left-radius: 0.375rem;
          }

          &:last-child {
            border-right-width: 1px;
            border-top-right-radius: 0.375rem;
          }
        }

        td {
          border-bottom-width: 1px;
          border-left-width: 1px;
          padding: 0.25rem 0.75rem;
        }

        &:last-child {
          border-bottom-width: 0;

          td {
            &:first-child {
              border-bottom-left-radius: 0.375rem;
            }

            &:last-child {
              border-bottom-right-radius: 0.375rem;
            }
          }
        }
      }
    }

    tr {
      th {
        background-color: rgba(161, 161, 161, 0.2);
        border-bottom-width: 1px;
        border-left-width: 1px;
        border-top-width: 1px;
        padding: 0.25rem 0.75rem;
        font-weight: 600;

        &:first-child {
          border-top-left-radius: 0.375rem;
        }

        &:last-child {
          border-right-width: 1px;
          border-top-right-radius: 0.375rem;
        }
      }

      td {
        border-bottom-width: 1px;
        border-left-width: 1px;
        padding: 0.25rem 0.75rem;
      }
    }
  }
}
