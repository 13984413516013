input[type='text'] {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-800);
  border-radius: 6px;
  color: var(--primary-0);
  box-shadow: none;
  height: 35px;
  border-width: 0;
}

input[type='number'] {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-800);
  border-radius: 6px;
  color: var(--primary-0);
  box-shadow: none;
  height: 35px;
  border-width: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
