@import './styles/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Bruno+Ace&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import 'ngx-toastr/toastr';
@import '@ng-select/ng-select/themes/default.theme.css';
@import '@material/button/dist/mdc.button.css';
@import '@material/icon-button/dist/mdc.icon-button.css';
@import '@material/ripple/dist/mdc.ripple.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';
@import 'primeng/resources/themes/lara-light-blue/theme.css';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

h3,
h4 {
  font-weight: 500;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
