.info-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 50px);
  width: calc(100% - 90px);
  margin-left: 90px;
}

.info-container {
  background-color: transparent;
  height: 100%;
  width: 100%;
  max-width: 1400px;
  padding: 40px 20px;
  backdrop-filter: blur(15px);
  border: 1px solid #fcce2c;
  border-radius: 32px;
  color: var(--primary-0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  line-height: 24px;

  .content-container {
    height: 100%;
    padding-bottom: 40px;

    .content {
      height: 100%;
      padding: 0 20px;
      overflow-y: auto;

      ol {
        list-style-position: inside;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #6b6767;
        border-radius: 4px;
      }
    }
  }

  h1 {
    margin-bottom: 20px;
    font-family: Bruno Ace;
    padding: 0 20px;
  }

  p,
  li {
    margin-bottom: 12px;
  }
}
