@import "highlight.js/styles/dark.css";
@import 'highlight.js/styles/atom-one-dark';

.hljs-title, .hljs-keyword {
  font-weight: normal;
}

.hljs {
  border-radius: 15px;
  margin: 10px 0px;
}
