select {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-800);
  border-radius: 6px;
  color: var(--primary-0);
  box-shadow: none;
  cursor: pointer;
  border-width: 0;
  height: 35px;

  select::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    background-color: var(--primary-450);
    transition: 0.25s all ease;
    pointer-events: none;
  }

  select:hover::after {
    color: var(--accent-700);
  }
}
