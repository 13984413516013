:root {
  --primary-0: #fff;
  --primary-50: #D4D4D4;
  --primary-100: #CDCDCD;
  --primary-150: #C8C8C8;
  --primary-200: #8E8E8E;
  --primary-250: #7E7E7E;
  --primary-300: #6B6B6B;
  --primary-350: #353228;
  --primary-400: #363636;
  --primary-450: #303030;
  --primary-500: #292929;
  --primary-550: #222222;
  --primary-600: #1D1D1D;
  --primary-700: #161616;
  --primary-800: #060607;
  --primary-900: #000000;

  --secondary-100: #887924;
  --secondary-200: #E8CB21;
  --secondary-300: #0CBE09;
  --secondary-400: #26E426;
  --secondary-500: #FF0000;

  --accent-50: #fefce4;
  --accent-100: #fcf6bd;
  --accent-200: #faef92;
  --accent-300: #f8e964;
  --accent-400: #f6e43e;
  --accent-500: #f5e003;
  --accent-600: #f4ce00;
  --accent-700: #e5b611;
  --accent-800: #e49f00;
  --accent-900: #d1a33c;
}