.mdc-icon-button {
  font-size: 24px;
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mdc-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mdc-icon-button.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mdc-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */
  /*rtl:ignore*/
  left: 50%;
  width: 48px;
  /* @noflip */
  /*rtl:ignore*/
  transform: translate(-50%, -50%);
}
.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
}
.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}

.mdc-icon-button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  z-index: 0;
  overflow: visible;
}
.mdc-icon-button .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */
  /*rtl:ignore*/
  left: 50%;
  width: 48px;
  /* @noflip */
  /*rtl:ignore*/
  transform: translate(-50%, -50%);
}
@media screen and (forced-colors: active) {
  .mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__focus-ring, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__focus-ring {
    display: block;
  }
}
.mdc-icon-button:disabled {
  cursor: default;
  pointer-events: none;
}
.mdc-icon-button[hidden] {
  display: none;
}

.mdc-icon-button--display-flex {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.mdc-icon-button__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 6px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */
  /*rtl:ignore*/
  left: 50%;
  /* @noflip */
  /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  display: none;
}
@media screen and (forced-colors: active) {
  .mdc-icon-button__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-icon-button__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 8px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */
  /*rtl:ignore*/
  left: 50%;
  /* @noflip */
  /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-icon-button__focus-ring::after {
    border-color: CanvasText;
  }
}

.mdc-icon-button__icon {
  display: inline-block;
}
.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}
.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button__link {
  height: 100%;
  left: 0;
  outline: none;
  position: absolute;
  top: 0;
  width: 100%;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    /* @noflip */
    /*rtl:ignore*/
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    /* @noflip */
    /*rtl:ignore*/
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
}
.mdc-icon-button .mdc-icon-button__ripple::before,
.mdc-icon-button .mdc-icon-button__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-icon-button .mdc-icon-button__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-icon-button .mdc-icon-button__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  top: 0;
  /* @noflip */
  /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-icon-button.mdc-ripple-upgraded--unbounded .mdc-icon-button__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-activation .mdc-icon-button__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation .mdc-icon-button__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  /* @noflip */
  /*rtl:ignore*/
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button .mdc-icon-button__ripple::before,
.mdc-icon-button .mdc-icon-button__ripple::after {
  top: calc(50% - 50%);
  /* @noflip */
  /*rtl:ignore*/
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::before,
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  /*rtl:ignore*/
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button.mdc-ripple-upgraded .mdc-icon-button__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button .mdc-icon-button__ripple::before, .mdc-icon-button .mdc-icon-button__ripple::after {
  background-color: #000;
  /* @alternate */
  background-color: var(--mdc-ripple-color, #000);
}
.mdc-icon-button:hover .mdc-icon-button__ripple::before, .mdc-icon-button.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-icon-button.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-icon-button:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-icon-button:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-icon-button:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-icon-button:disabled:hover .mdc-icon-button__ripple::before, .mdc-icon-button:disabled.mdc-ripple-surface--hover .mdc-icon-button__ripple::before {
  opacity: 0;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0);
}
.mdc-icon-button:disabled.mdc-ripple-upgraded--background-focused .mdc-icon-button__ripple::before, .mdc-icon-button:disabled:not(.mdc-ripple-upgraded):focus .mdc-icon-button__ripple::before {
  transition-duration: 75ms;
  opacity: 0;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0);
}
.mdc-icon-button:disabled:not(.mdc-ripple-upgraded) .mdc-icon-button__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-icon-button:disabled:not(.mdc-ripple-upgraded):active .mdc-icon-button__ripple::after {
  transition-duration: 75ms;
  opacity: 0;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0);
}
.mdc-icon-button:disabled.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0);
}
.mdc-icon-button .mdc-icon-button__ripple {
  height: 100%;
  left: 0px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: -1;
}